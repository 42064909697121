export const data = [
    {
        "position": 1,
        "videoId": "qixrjvamhq",
        "videoTitle": "Long-Term Health for People With Narcolepsy",
        "videoDescription": "Learn about certain health conditions that may be associated with narcolepsy, and how people with narcolepsy can maintain a healthy lifestyle.",
        "speakerName": "Name",
        "speakerTitle": "Title",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/645eb21f6b4eb34d1bb87098ce246ae1.png",
        "videoTranscript": [
            { "paragraph": "Welcome to our Jazz Pharmaceuticals Webinar." },
            { "paragraph": "Today, we’ll discuss long-term health for people with narcolepsy, including certain conditions that may be associated with narcolepsy, and how you can maintain a healthy lifestyle." },
            { "paragraph": "During today’s presentation, we’ll discuss:  How narcolepsy may impact your long-term health" },
            { "paragraph": "There are a couple of topics we won’t be able to address today. We can’t comment on how narcolepsy may be affecting your own personal health  And we can’t give advice on any specific strategies you personally should follow to improve your overall health" },
            { "paragraph": "Let’s take a look at the 5 major symptoms of narcolepsy. The essential symptom is excessive daytime sleepiness, or EDS, which means that you get overcome by an irresistible need to sleep during the day, and you can feel tired a lot of the time. \r Cataplexy is sudden muscle weakness triggered by strong emotions like embarrassment, laughter, surprise, or anger. Cataplexy can cause your head to drop, your face to droop, your jaw to weaken, or your knees to give way. Attacks can also affect your whole body and cause you to fall down. \r Disrupted nighttime sleep is more than just waking up during the night once in awhile. It means you often fall asleep quickly but wake up frequently throughout the night. Disrupted nighttime sleep can have a major impact on people’s lives.\r Sleep paralysis is the inability to move or speak for a short time when you’re falling asleep or waking up. Episodes usually last seconds to minutes and can end by themselves or from being touched, shaken, or spoken to, or after trying hard to move.\r Vivid dreamlike experiences—called hypnagogic or hypnopompic hallucinations—can seem real and are often frightening. Hypnagogic hallucinations occur when falling asleep, and hypnopompic hallucinations occur when waking up." },
            { "paragraph": "Managing narcolepsy symptoms is challenging enough, but narcolepsy is often associated with other medical conditions.\r In a study, people with narcolepsy were also shown to have higher rates of psychological conditions such as depression, anxiety, and other psychiatric disorders such as bipolar disorders or agoraphobia. \r Also, compared to people who do not have narcolepsy, people with narcolepsy were shown to have higher rates of high cholesterol, high blood pressure, diabetes, and obesity. \r And, people with narcolepsy were shown to be 2 times more likely to have heart diseases." },
            { "paragraph": "Let’s take a closer look at psychological conditions that are often observed among people with narcolepsy. According to a study, people with narcolepsy are over 2.5 times more likely than those without narcolepsy to have major depressive disorder, 4.5 times more likely to have bipolar disorder, nearly 6.5 times more likely to have agoraphobia, over 3 times more likely to have panic disorder, over 3.5 times more likely to have obsessive-compulsive disorder, and over 3 times more likely to have generalized anxiety disorder." },
            { "paragraph": "These other health conditions that are more commonly found among people with narcolepsy also often require treatment. According to the same study, people with narcolepsy are more likely to be taking certain types of medication for these comorbidities.  For example, they are 3x more likely to be taking an antidepressant, nearly 3.5x more likely to be taking an antipsychotic medication, nearly 3.5 times more likely to be taking a hypnotic, over 4x more likely to be taking an antiasthmatic, nearly 2x more likely to be taking a cholesterol-lowering drug, and nearly 4x more likely to be taking a diuretic to lower blood pressure." },
            { "paragraph": "As a person with narcolepsy, what does all this mean for you? First, you need to be aware that you may have a higher likelihood of developing other health conditions. Also, keep in mind that it’s important to pay attention to your overall health. And you may want to talk with your doctor about a holistic narcolepsy management plan." },
            { "paragraph": "An increased rate of cardiovascular conditions has been reported among people with narcolepsy. However, some risk factors for cardiovascular diseases are “modifiable” in the general population. That means you may be able to limit their impact on your health. For example: Smoking has been shown to increase the risk of developing cardiovascular diseases Physical activity has been shown to help prevent cardiovascular diseases among the general population and may improve outcomes for people who already have cardiovascular diseases A heart-healthy diet, with plenty of whole grains, fruits and vegetables, and fiber, and limited quantities of meat, saturated fat, and sodium, has been associated with lower risk of cardiovascular diseases in the general population Your body weight can be a factor in developing cardiovascular diseases. Even a 5% weight loss has real health benefits  How much sodium is in your diet? Too much sodium may increase the risk of cardiovascular diseases  Remember, changing your behavior in any of these areas may help reduce your risk for cardiovascular disease." },
            { "paragraph": "Let’s learn more about sodium intake as a modifiable risk factor. Did you know that, according to an analysis by the Centers for Disease Control and Prevention of the 2009-2012 National Health and Nutrition Examination Survey data, about 9 out of 10 adult Americans take in too much sodium? The average daily intake of over 3400 mg/day is quite a bit higher than the upper intake limit of 2300 mg/day recommended by the Department of Health and Human Services and US Department of Agriculture. And the American Heart Association recommends an ideal daily target of no more than 1500 mg of sodium a day." },
            { "paragraph": "How can sodium intake impact your health?  Too much sodium may increase the risk for high blood pressure, cardiovascular diseases, and stroke." },
            { "paragraph": "On the other hand, cutting back on sodium can reduce blood pressure, even for those who do not have high blood pressure. So you may want to take a look at the sodium in what you eat and drink as well as the sodium in any medications you take." },
            { "paragraph": "Some foods and medications are surprisingly high in sodium. For example: did you know that a large order of French fries can have up to 400 mg of sodium? And that a can of chicken noodle soup can have nearly 900 mg of sodium? Beverages can also contain a lot of sodium: a 20-oz sports drink contains over 250 mg. Certain medications are also high in sodium. Effervescent pain relievers, for example, have about 500 mg of sodium per tablet." },
            { "paragraph": "As we’ve seen, people with narcolepsy need to think about other health conditions that may be associated with narcolepsy. That’s why it’s a good idea to talk with your healthcare provider about how to manage your overall health. Since narcolepsy is a chronic condition that usually involves long-term treatment, you’ll need a management plan that takes your overall health into account.  So you may want to discuss all aspects of your health with your healthcare provider when creating your plan. For example: Discuss your exercise habits and lifestyle, and talk to your doctor before starting any exercise plan Ask about your diet, including sodium consumption Talk about other conditions you may have or may be at risk for And, taking small steps to improve your health can make a big difference! For example, walking just a half-hour a day, 5 days a week, at a moderate intensity, may reduce the risk of cardiovascular diseases." },
            { "paragraph": "When working with your healthcare provider on your narcolepsy management plan, keep in mind that your plan should be individualized for your needs, goals, and symptoms. Your management plan can be guided by the impact of narcolepsy on your school, work, social life, safety, and relationships. Part of your narcolepsy management plan may involve choosing a treatment for your symptoms.  The choice of medication may depend on your symptoms, the medication itself, and your lifestyle and expectations." },
            { "paragraph": "So to wrap it all up, what are our key takeaways from this presentation?" },
            { "paragraph": "Let's summarize what we've learned... People with narcolepsy may be more likely to have certain other medical conditions compared to those without narcolepsy Sodium intake is a modifiable risk factor among the general population Your narcolepsy management plan should be individualized for your needs, goals, and symptoms" },
            { "paragraph": "Let’s look at some potential next steps. You can:  Talk with your healthcare provider about your overall health  See what you can do to reduce your risk for health conditions often associated with narcolepsy Work with your healthcare provider on a personalized narcolepsy management plan" },
            { "paragraph": "Thank you for your time today! I hope this presentation has given you useful information about narcolepsy and your long-term health." },
            { "paragraph": "" },
            { "paragraph": "" }
        ],
        "videoResource": [
            {
                "resourceTitle": "People with narcolepsy may want to consider their overall health",
                "resourceDescription": "Find out why and get tips for your well-being.",
                "resourceLinkName": "GET TIPS ON OVERALL HEALTH",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/narcolepsy-health-tips/"
            },
            {
                "resourceTitle": "Don't take on narcolepsy alone",
                "resourceDescription": "Connect with others that have narcolepsy to help in your journey with this condition.",
                "resourceLinkName": "JOIN A NARCOLEPSY COMMUNITY",
                "resourceLink": "https://www.morethantired.com/narcolepsy-community/ "
            }
        ]
    },
    {
        "position": 2,
        "videoId": "gamz148yja",
        "videoTitle": "Narcolepsy Type 1: Potential Autoimmune Causes",
        "videoDescription": "Catch up on theories and research about the possible autoimmune causes of narcolepsy type 1.",
        "speakerName": "Asim Roy, MD",
        "speakerTitle": "Medical Director of the Ohio Sleep Medicine Institute",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/fb8d12876dfb8ceb37b566b62030f394.webp",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Get news and information on narcolepsy in your inbox",
                "resourceDescription": "Register to receive email updates from More Than Tired to stay on top of this condition.",
                "resourceLinkName": "Sign up today!",
                "resourceLink": "https://www.morethantired.com/stay-connected"
            },
            {
                "resourceTitle": "There’s more to living with narcolepsy than managing the symptoms",
                "resourceDescription": "Learn more about managing your overall health, tips for your well-being, and find resources to help.",
                "resourceLinkName": "Find out more!",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/"
            }
        ]
    },
    {
        "position": 3,
        "videoId": "1f9q56jgv8",
        "videoTitle": "The Science of Narcolepsy",
        "videoDescription": "Discover scientific findings around the causes of narcolepsy and how the 5 phases of sleep are disrupted in people with this condition.",
        "speakerName": "Logan Schneider, MD",
        "speakerTitle": "Clinical Assistant Professor of Sleep Medicine at Stanford University Medical Cente",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/10386037739543c0072f0c4d3bbd2cf2.webp",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Get news and information on narcolepsy in your inbox",
                "resourceDescription": "Register to receive email updates from More Than Tired to stay on top of this condition.",
                "resourceLinkName": "Sign up today!",
                "resourceLink": "https://www.morethantired.com/stay-connected/"
            },
            {
                "resourceTitle": "There’s more to living with narcolepsy than managing the symptoms",
                "resourceDescription": "Learn more about managing your overall health, tips for your well-being, and resources to help.",
                "resourceLinkName": "Find out more!",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/"
            }
        ]
    },
    {
        "position": 4,
        "videoId": "r0vwlqag9v",
        "videoTitle": " Living Your Healthiest Life With Narcolepsy",
        "videoDescription": "Find out which medical conditions people with narcolepsy may be at higher risk for. Learn ways to help reduce the risk of developing these health conditions.",
        "speakerName": "",
        "speakerTitle": "",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/d0c0ad89f9e6c79366fddfac6c09dbdd.jpg",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Want more information?",
                "resourceDescription": "Sign up to receive information and support for people living with narcolepsy.",
                "resourceLinkName": "Stay connected",
                "resourceLink": "https://www.morethantired.com/stay-connected/"
            },
            {
                "resourceTitle": "Living with narcolepsy?",
                "resourceDescription": "Make sure your management plan takes your overall health into account.",
                "resourceLinkName": "Learn more",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/"
            },
            {
                "resourceTitle": "Appointment coming up?",
                "resourceDescription": "Learn how to talk with your doctor at your next appointment.",
                "resourceLinkName": "Get ready for your visit",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/narcolepsy-treatment-options/"
            }
        ]
    },
    {
        "position": 5,
        "videoId": "59rvw05ofj",
        "videoTitle": "Nutrition Considerations When Living With Narcolepsy",
        "videoDescription": "Learn how people with narcolepsy are more likely to develop cardiovascular issues. Get nutrition information that may help mitigate the risks of these conditions.",
        "speakerName": "",
        "speakerTitle": "",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/6e4d9cc36ad1a6ce17ed1e7c567a69fa.jpg",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Want more information?",
                "resourceDescription": "Sign up to receive information and support for people living with narcolepsy.",
                "resourceLinkName": "Stay connected",
                "resourceLink": "https://www.morethantired.com/stay-connected"
            },
            {
                "resourceTitle": "Wondering about narcolepsy and your overall health?",
                "resourceDescription": "Learn why it’s important to take your overall health into account.",
                "resourceLinkName": "Learn more",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/related-conditions"
            },
        ]
    },
    {
        "position": 6,
        "videoId": "deb63bh7ba",
        "videoTitle": "Taking Narcolepsy to Heart: A Cardiologist’s Viewpoint",
        "videoDescription": "Hear a cardiologist explain how and why people with narcolepsy are at a higher risk for cardiovascular conditions. Plus, learn what steps you can take that may help to reduce that risk.",
        "speakerName": "",
        "speakerTitle": "",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/fd96eaf7da8bc6409e3b99c4d2990a03.jpg",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Want more information?",
                "resourceDescription": "Sign up to receive information and support for people living with narcolepsy.",
                "resourceLinkName": "Stay connected",
                "resourceLink": "https://www.morethantired.com/stay-connected"
            },
            {
                "resourceTitle": "Living with narcolepsy?",
                "resourceDescription": "Make sure your management plan takes your overall health into account.",
                "resourceLinkName": "Learn more",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/"
            },
        ]
    },
    {
        "position": 7,
        "videoId": "3wsl0kadl5",
        "videoTitle": "Navigating Narcolepsy in the Workplace: A Personal Story",
        "videoDescription": "Hear a person with narcolepsy share their experience navigating narcolepsy in the workplace and get practical tips on advocating for yourself at work.",
        "speakerName": "",
        "speakerTitle": "",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/b7e448df6aae80d97cf6903457ec7834.jpg",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Want more information?",
                "resourceDescription": "Sign up to receive information and support for people living with narcolepsy.",
                "resourceLinkName": "Stay connected",
                "resourceLink": "https://www.morethantired.com/stay-connected"
            },
            {
                "resourceTitle": "Wondering about narcolepsy and your overall health?",
                "resourceDescription": "Learn why it’s important to take your overall health into account.",
                "resourceLinkName": "Learn more",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/related-conditions/"
            },
            {
                "resourceTitle": "Appointment coming up?",
                "resourceDescription": "Learn how to talk with your doctor at your next appointment.",
                "resourceLinkName": "Get ready for your visit",
                "resourceLink": "https://www.morethantired.com/living-with-narcolepsy/narcolepsy-treatment-options/"
            },
        ]
    }
]